<template>
  <el-card>
    <div slot="header">
      <span>制单表</span>
    </div>
    <el-row :gutter="20">
      <el-col :span="4"
        ><el-input
          v-model="queryFilter.keyWorlds"
          placeholder="请输入订单相关信息进行筛选"
          clearable
      /></el-col>
      <el-col :span="4">
        <el-select
          v-model="queryFilter.type"
          clearable
          placeholder="制作订单类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="queryFilter.status"
          clearable
          placeholder="制作订单状态"
        >
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="7">
        <el-date-picker
          v-model="timer"
          type="datetimerange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-col>
      <el-button type="primary" @click="flashTable">查询订单</el-button>
      <el-button type="primary" style="float: right" @click="pushAddMackOrder"
        >新增订单</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-table
        :data="mackOrderData"
        style="width: 100%"
        row-key="id"
        @expand-change="expandChange"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand_box">
              <el-table
                ref="productRef"
                :data="scope.row.productList"
                :summary-method="getSummaries"
                :show-summary="true"
                v-loading="scope.row.loading"
                border
              >
                <el-table-column
                  label="#"
                  width="60"
                  type="index"
                ></el-table-column>
                <el-table-column
                  label="商品名称"
                  prop="productName"
                ></el-table-column>
                <el-table-column label="型号规格">
                  <template v-slot="scope">
                    {{
                      (scope.row.modelName || scope.row.productModel || '无') +
                      `(${scope.row.productSpecs || '无'})`
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品数量"
                  prop="productAmount"
                ></el-table-column>
                <el-table-column
                  label="原价"
                  prop="productPrice"
                ></el-table-column>
                <el-table-column
                  :label="
                    (scope.row.discount
                      ? `折扣(${scope.row.discount}折)+`
                      : '') +
                    '含税价(' +
                    scope.row.taxRate +
                    '%)'
                  "
                  prop="productTaxPrice"
                ></el-table-column>
                <el-table-column
                  prop="productTotalPrice"
                  label="单个商品总价格"
                >
                </el-table-column>
                <el-table-column
                  label="商品备注"
                  prop="remark"
                ></el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column width="200" label="订单编号">
          <template slot-scope="scope">
            <el-link
              style="font-size: 12px"
              :underline="false"
              @click="handleCopy(scope.row.orderNumber, $event)"
              >{{ scope.row.orderNumber }}</el-link
            >
          </template>
        </el-table-column>

        <el-table-column label="订单类型" width="100">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.mackOrderType == 0 ? '' : 'success'"
              effect="dark"
            >
              {{ scope.row.mackOrderTypeDisplayName }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="订单状态" width="100">
          <template slot-scope="scope">
            <el-tag :type="tagType(scope.row.orderState)" effect="dark">
              {{ scope.row.mackOrderStateDisplayName }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column width="170" prop="createTime" label="订单创建时间">
        </el-table-column> -->
        <el-table-column prop="updateTime" show-overflow-tooltip label="更新时间">
        </el-table-column>
        <!-- <el-table-column
          prop="owinUserName"
          label="用户名"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column
          prop="customerCompany"
          label="公司名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column
          prop="customerName"
          label="联系人"
          show-overflow-tooltip
        >
        </el-table-column> -->

        <el-table-column prop="remark2" label="系统备注" show-overflow-tooltip>
        </el-table-column>

        <!-- <el-table-column prop="customerPhone" label="联系电话"> -->
        <!-- </el-table-column> -->
        <el-table-column label="是否开票">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isMakeOutAnInvoice" effect="dark">
              已开票
            </el-tag>
            <el-tag v-else type="info" effect="dark"> 未开票 </el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="customerAddress" label="地址"> </el-table-column> -->
        <!-- <el-table-column prop="discount" label="折扣(折)"> </el-table-column> -->
        <!-- <el-table-column prop="taxRate" label="税率(%)"> </el-table-column> -->
        <el-table-column prop="payAmount" label="支付金额"> </el-table-column>
        <!-- <el-table-column prop="userName" label="制单人"> </el-table-column> -->
        <!-- <el-table-column prop="Remark" label="备注"> </el-table-column> -->
        <el-table-column label="编辑">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              style="color: #67c23a"
              @click="OrderDetail(scope.row.id)"
              icon="el-icon-message"
              >详情</el-button
            >
            <el-button
              size="mini"
              :disabled="scope.row.orderState != 0 && scope.row.orderState != 8"
              @click="editMackOrder(scope.row.id)"
              type="text"
              icon="el-icon-edit"
              >编辑</el-button
            >
            <el-button
              size="mini"
              @click="openRemark2Dialog(scope.row)"
              type="text"
              icon="el-icon-edit"
              >修改备注</el-button
            >
            <el-button
              size="mini"
              :disabled="scope.row.orderState != 0 && scope.row.orderState != 8"
              @click="deleteMakckOrder(scope.row)"
              type="text"
              style="color: #f56c6c"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>

    <el-dialog title="" :visible.sync="orderDetailDataDialog">
      <el-row>
        <el-col :span="8">订单号:{{ orderDetailData.orderNumber }}</el-col>
        <el-col :span="8"
          >客户名称:{{ orderDetailData.customerCompany }}</el-col
        >
        <el-col :span="8">联系人:{{ orderDetailData.customerName }}</el-col>
        <el-col :span="8">联系电话:{{ orderDetailData.customerPhone }}</el-col>
        <el-col :span="8">地址:{{ orderDetailData.customerAddress }}</el-col>
        <el-col :span="8">税率:{{ orderDetailData.taxRate }}%</el-col>
        <el-col :span="8">折扣:{{ orderDetailData.discount || '10' }}折</el-col>
        <el-col :span="8">制单人:{{ orderDetailData.userName }}</el-col>
        <el-col :span="8"
          >订单状态:{{ orderDetailData.mackOrderStateDisplayName }}</el-col
        >
        <el-col :span="8">备注:{{ orderDetailData.remark }}</el-col>
      </el-row>

      <el-table :data="orderDetailData.productList" border :max-height="600">
        <el-table-column label="#" width="60" type="index"></el-table-column>
        <el-table-column label="商品名称" prop="productName"></el-table-column>
        <el-table-column label="型号规格">
          <template v-slot="scope">
            {{
              (scope.row.modelName ||
                scope.row.productModel ||
                scope.row.productModel ||
                '无') + `(${scope.row.productSpecs || '无'})`
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="商品数量"
          prop="productAmount"
        ></el-table-column>
        <el-table-column label="原价" prop="productPrice"></el-table-column>
        <el-table-column
          :label="
            (orderDetailData.discount
              ? `折扣(${orderDetailData.discount}折)+`
              : '') +
            '含税价(' +
            orderDetailData.taxRate +
            '%)'
          "
          prop="productTaxPrice"
        ></el-table-column>
        <el-table-column prop="productTotalPrice" label="商品总价格">
        </el-table-column>
        <el-table-column label="商品备注" prop="remark"></el-table-column>
      </el-table>
      <el-row style="margin-top: 20px; text-align: right">
        总价格:{{ orderDetailDataTotalPrice }}
      </el-row>
      <!-- 审核进度 -->
      <el-row style="margin-top: 20px">
        <el-row
          ><span>审核进度:</span>
          <el-button
            type="success"
            :disabled="canAudit"
            style="float: right"
            size="mini"
            @click="auditeDialog = true"
            >提交审核</el-button
          ></el-row
        >
        <el-timeline style="margin-top: 20px">
          <el-timeline-item
            v-for="(log, index) in orderDetailData.auditLogList"
            :key="index"
            :timestamp="log.createTime"
            :color="getTimeTimeLineColor(log)"
          >
            <el-card>
              <template v-if="log.auditState == 0">
                <el-tag type="info">等待审核中</el-tag>
                <span style="float: right">
                  <el-button
                    type="danger"
                    size="mini"
                    @click="openAuditCheckDialog(false)"
                    >不通过审核</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="openAuditCheckDialog(true)"
                    >通过审核</el-button
                  >
                </span>
              </template>
              <el-tag v-else-if="log.auditState == 1" type="success"
                >审核成功</el-tag
              >
              <el-tag v-else type="danger">审核失败</el-tag>
              <el-row type="flex" style="margin-top: 20px">
                <el-image
                  v-for="(imgUrl, iindex) in log.fileUrlArr"
                  :key="'auditImg' + iindex"
                  style="width: 100px; height: 100px"
                  :src="imageBaseUrl + imgUrl"
                  :preview-src-list="[imageBaseUrl + imgUrl]"
                  fit="contain"
                ></el-image>
                <span style="padding: 10px; color: red">{{
                  log.auditRecommendations
                }}</span>
              </el-row>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </el-row>
      <!-- 发货进度 -->
      <el-row style="margin-top: 20px">
        <el-row
          ><span>发货进度:</span>
          <el-button
            type="success"
            :disabled="canDelivery"
            style="float: right"
            size="mini"
            @click="openDelliverDialog"
            >去发货</el-button
          ></el-row
        >
        <div
          v-for="(delivery, index) in orderDetailData.deliveryGoodsList"
          :key="'delivery' + index"
        >
          <span>快递编号:</span
          ><el-link
            type="primary"
            @click="opendeliverDetialDialog(delivery.details)"
            >{{ delivery.courierNumber }}</el-link
          >
        </div>
      </el-row>

      <!-- 发票内容 -->
      <el-row v-show="canInvoiceLog" style="margin-top: 20px">
        <el-row
          ><span>发票内容:</span>
          <el-button
            type="primary"
            :disabled="!canInvoiceLog"
            style="float: right"
            size="mini"
            @click="upInvoiceDialog = true"
            >开发票</el-button
          ></el-row
        >
        <div
          v-for="(invoiceLog, index) in orderDetailData.invoiceLogList"
          :key="'invoice' + index"
        >
          <span>发票:</span
          ><el-link
            type="primary"
            @click="openPdfDialog(invoiceLog.filePath)"
            >{{ invoiceLog.invoiceType }}</el-link
          >
        </div>
      </el-row>

      <!-- 提交审核对话框 -->
      <el-dialog
        append-to-body
        :visible.sync="auditeDialog"
        @close="clearUpload('upload')"
      >
        <el-upload
          style="width: 100%"
          multiple
          accept="image/jpeg,image/jpg,image/png"
          :limit="3"
          :on-change="changFile"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :auto-upload="false"
          ref="upload"
          action=""
          list-type="picture-card"
        >
          <div slot="file" slot-scope="{ file }">
            <el-image
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              style="width: 146px; height: 146px"
              fit="contain"
            ></el-image>
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                class="el-upload-list__item-delete"
                @click="removeFile(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>

          <i slot="default" class="el-icon-plus"></i>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过2M
          </div>
        </el-upload>

        <el-dialog append-to-body :visible.sync="imgeShowDialog">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>

        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="auditeDialog = false">取消</el-button>
          <el-button type="primary" @click="auditDialogCheck">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 审核建议对话框 -->
      <el-dialog append-to-body :visible.sync="aduitCheckDialog">
        <el-form>
          <el-form-item label="审核意见:">
            <el-input
              type="text"
              v-model="auditForm.remark"
              placeholder="请输入审核意见"
            ></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="aduitCheckDialog = false"
            >取消</el-button
          >
          <el-button type="primary" @click="aduitCheckCheck">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 发货单 -->
      <el-dialog append-to-body :visible.sync="deliverProductDialog">
        <el-form inline>
          <el-form-item label="快递单号:">
            <el-input
              type="text"
              v-model="deliverProductForm.customerNumber"
              placeholder="请输入快递单号"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-row>
          <el-table
            :data="deliveryProductData"
            stripe
            style="width: 100%"
            border
            :key="tablekey"
          >
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column label="商品名称" prop="productName">
            </el-table-column>
            <el-table-column label="型号">
              <template slot-scope="scope">
                {{ scope.row.modelName || scope.row.productModel || '无' }}
              </template>
            </el-table-column>
            <el-table-column label="规格">
              <template slot-scope="scope">
                {{ scope.row.productSpecs || '无' }}
              </template>
            </el-table-column>
            <el-table-column label="商品总数" prop="productAmount">
            </el-table-column>
            <el-table-column label="已发货数量" prop="sendAmount">
            </el-table-column>
            <el-table-column label="发货数量">
              <template slot-scope="scope">
                <el-input-number
                  :min="0"
                  :max="scope.row.productAmount - scope.row.sendAmount"
                  size="mini"
                  v-model="scope.row.count"
                  @change="() => (tablekey = !tablekey)"
                ></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="deliverProductDialog = false"
            >取消</el-button
          >
          <el-button type="primary" @click="deliveryDialogCheck"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!-- 订单快递详情 -->
      <el-dialog append-to-body :visible.sync="deliverDetialDialog">
        <el-row>
          <el-table :data="deliverDetialData" stripe style="width: 100%" border>
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column label="商品名称" prop="productName">
            </el-table-column>
            <el-table-column label="发货数量" prop="amount"> </el-table-column>
            <el-table-column prop="modelName" label="型号"> </el-table-column>
            <el-table-column prop="productSpecs" label="规格"></el-table-column>
          </el-table>
        </el-row>

        <el-row style="margin-top: 20px">
          <el-button size="mini" @click="printDataDialog = true"
            >预览打印出库单</el-button
          >
          <el-button size="mini" @click="zjPrintPage">直接打印</el-button>
        </el-row>

        <el-dialog append-to-body :visible.sync="printDataDialog">
          <!--startprint-->
          <div ref="printBox">
            <template
              v-for="i in Math.ceil(deliveryGoodsDetialDataComp.length / 10)"
            >
              <div :key="'li' + i" class="print_box">
                <div class="print_box_head">
                  <img :src="snowyiPng" />杭州雪意科技有限公司销售出库单
                  <barcode
                    class="barcode_style"
                    :width="1"
                    :font-size="12"
                    :height="25"
                    :value="orderDetailData.orderNumber"
                  />
                </div>
                <div class="print_box_info">
                  <div>
                    出库日期&nbsp;{{ new Date() | timeFormat('yyyy-MM-dd') }}
                  </div>
                  <div>
                    业务类型&nbsp;{{
                      orderDetailData.outStorageTypeDisplayName
                    }}
                  </div>
                  <div>仓库&nbsp;杭州</div>
                  <div>出库类别&nbsp;发货</div>
                  <div>销售部门&nbsp;国内销售</div>
                  <div>客户&nbsp;{{ orderDetailData.owinUserName }}</div>
                  <div style="width: 100%">
                    备注&nbsp;{{ orderDetailData.companyName }}&nbsp;{{
                      orderDetailData.remark
                    }}
                  </div>
                </div>
                <div class="print_table_box">
                  <table
                    align="center"
                    cellPadding="0"
                    cellSpacing="0"
                    style=""
                    width="700"
                  >
                    <thead>
                      <tr>
                        <th>库存编号</th>
                        <th>存货名称</th>
                        <th>规格型号</th>
                        <th>主计量单位</th>
                        <th>数量</th>
                        <th>备注</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="item in pagination(
                          i,
                          10,
                          deliveryGoodsDetialDataComp
                        )"
                      >
                        <tr v-if="!item.isSoft" :key="item.id">
                          <td width="80">{{ item.productNo }}</td>
                          <td width="260">
                            {{ item.productName }}
                          </td>
                          <td width="100">
                            {{
                              (item.modelName || item.productModel || '') +
                              '' +
                              (item.productSpecs || '')
                            }}
                          </td>
                          <td width="70">{{ item.productUnit }}</td>
                          <td width="80">{{ item.amount }}</td>
                          <td width="90">{{ '' }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div class="print_box_foot">
                  <div>制单人&nbsp;智美OA</div>
                  <div>审核人</div>
                  <div style="flex: 1; text-align: right">
                    总计:{{ deliveryGoodsDetialCount }}件
                  </div>
                  <div style="flex-grow: 1; text-align: right">
                    第{{ i }}页&nbsp;共{{
                      Math.ceil(deliveryGoodsDetialDataComp.length / 10)
                    }}页
                  </div>
                </div>
              </div>
              <div
                v-if="i != Math.ceil(deliveryGoodsDetialDataComp.length / 10)"
                :key="'pageNex' + i"
                style="page-break-after: always"
              ></div>
            </template>
          </div>
          <!--endprint-->
          <div slot="footer" class="dialog-footer">
            <el-button @click="printDataDialog = false">取 消</el-button>
            <el-button type="primary" @click="printpage">打 印</el-button>
          </div>
        </el-dialog>
        <!-- 直接打印部分 start -->
        <div style="display: none" ref="zhijieprintBox">
          <template
            v-for="i in Math.ceil(deliveryGoodsDetialDataComp.length / 10)"
          >
            <div :key="'li' + i" class="print_box">
              <div class="print_box_head">
                <img :src="snowyiPng" />杭州雪意科技有限公司销售出库单
                <barcode
                  class="barcode_style"
                  :width="1"
                  :font-size="12"
                  :height="25"
                  :value="orderDetailData.orderNumber"
                />
              </div>
              <div class="print_box_info">
                <div>
                  出库日期&nbsp;{{ new Date() | timeFormat('yyyy-MM-dd') }}
                </div>
                <div>业务类型&nbsp;人工订单出库</div>
                <div>仓库&nbsp;杭州</div>
                <div>出库类别&nbsp;发货</div>
                <div>销售部门&nbsp;国内销售</div>
                <div>客户&nbsp;{{ orderDetailData.owinUserName }}</div>
                <div style="width: 100%">
                  备注&nbsp;{{ orderDetailData.companyName }}&nbsp;{{
                    orderDetailData.remark
                  }}
                </div>
              </div>
              <div class="print_table_box">
                <table
                  align="center"
                  cellPadding="0"
                  cellSpacing="0"
                  style=""
                  width="700"
                >
                  <thead>
                    <tr>
                      <th>库存编号</th>
                      <th>存货名称</th>
                      <th>规格型号</th>
                      <th>主计量单位</th>
                      <th>数量</th>
                      <th>备注</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="item in pagination(
                        i,
                        10,
                        deliveryGoodsDetialDataComp
                      )"
                    >
                      <tr v-if="!item.isSoft" :key="item.id">
                        <td width="80">{{ item.productNo }}</td>
                        <td width="260">
                          {{ item.productName }}
                        </td>
                        <td width="100">
                          {{
                            (item.modelName || item.productModel || '') +
                            ' ' +
                            (item.productSpecs || '')
                          }}
                        </td>
                        <td width="70">{{ item.productUnit }}</td>
                        <td width="80">{{ item.amount }}</td>
                        <td width="90">{{ '' }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="print_box_foot">
                <div>制单人&nbsp;智美OA</div>
                <div>审核人</div>
                <div style="flex: 1; text-align: right">
                  总计:{{ deliveryGoodsDetialCount }}件
                </div>
                <div style="flex-grow: 1; text-align: right">
                  第{{ i }}页&nbsp;共{{
                    Math.ceil(deliveryGoodsDetialDataComp.length / 10)
                  }}页
                </div>
              </div>
            </div>
            <div
              v-if="i != Math.ceil(deliveryGoodsDetialDataComp.length / 10)"
              :key="'pageNex' + i"
              style="page-break-after: always"
            ></div>
          </template>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="deliverDetialDialog = false"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- 上传发票对话框 -->
      <el-dialog
        title="上传发票"
        append-to-body
        :visible.sync="upInvoiceDialog"
        @close="closeUpInvoiceDialogHandle"
      >
        <el-form :v-model="upInvoiceForm" ref="addFromRef">
          <el-form-item prop="invoiceType" label="发票类型">
            <el-autocomplete
              v-model="upInvoiceForm.invoiceType"
              placeholder="请输入发票类型"
              :fetch-suggestions="queryInvoiceTypeSearch"
            ></el-autocomplete>
          </el-form-item>
          <!-- <el-form-item prop="courierNumber" label="发票快递">
            <el-autocomplete
              type="text"
              v-model="upInvoiceForm.courierNumber"
              :fetch-suggestions="querySearch"
            ></el-autocomplete>
          </el-form-item> -->
          <el-form-item prop="file" label="上传文件:">
            <el-upload
              ref="uploadInvoice"
              action="#"
              :http-request="httpRequestInvoice"
              :before-upload="beforeUploadInvoice"
              :on-exceed="handleExceedInvoice"
              :limit="1"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <div slot="tip" class="el-upload__tip">
                只能上传.pdf文件，且不超过20M
              </div>
            </el-upload>
          </el-form-item>
          <!-- <el-progress
            v-show="isStartUpload"
            :percentage="uploadValue"
          ></el-progress> -->
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="upInvoiceDialog = false">取 消</el-button>
          <el-button type="primary" @click="upInvoiceFrom">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog append-to-body :visible.sync="pdfShowDialog">
        <div style="width: 100%; height: 500px">
          <iframe
            width="100%"
            height="100%"
            scrolling="no"
            :src="`/lib/pdf/web/viewer.html?file=${pdfSrc}`"
          ></iframe>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="success"
          style="float: left"
          @click="OrderDetailDownLoad"
          >下载订单文件</el-button
        >
        <el-button type="primary" @click="orderDetailDataDialog = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 修改制作订单备注2信息 -->
    <el-dialog :visible.sync="mackOrderRemark2Dialog" title="修改备注">
      <el-form :model="mackOrderRemark2Form" label-width="120px">
        <el-form-item :v-show="false" label="Id">{{
          mackOrderRemark2Form.id
        }}</el-form-item>
        <el-form-item label="备注信息">
          <el-input
            type="textarea"
            :rows="5"
            v-model="mackOrderRemark2Form.remark2"
            placeholder="请输入备注信息"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="mackOrderRemark2Dialog = false">取消</el-button>
        <el-button @click="submitRemark2">确认</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import axios from 'axios'
import snowyiPng from '@/assets/snowyi.png'
import VueBarcode from 'vue-barcode'
import { timeOffset } from '@/utils/zoneTime.js'
import clip from '@/utils/clipboard'
import {
  getMackOrder,
  getMackOrderDetail,
  downLoadMackOrder,
  deleteOrder,
  upAuditeFile,
  getAuditeLog,
  getDeliveryDetial,
  auditOrder,
  deliveryOrder,
  upInvoiceFile,
  getMackOrderStateOptions,
  getMackOrderTypeOptions,
  changeMackOrderRemark2,
} from '@/api/order.js'
export default {
  data() {
    return {
      snowyiPng: snowyiPng + '?' + new Date(),
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
        type: null,
      },
      timer: '',
      stateOptions: [],
      typeOptions: [],
      mackOrderData: [],
      orderDetailDataDialog: false,
      // 订单详情信息
      orderDetailData: {
        productList: [],
        auditLogList: [],
      },
      // 审核订单
      auditeDialog: false,
      // 审核表单
      fileList: [],
      imgeShowDialog: false,
      dialogImageUrl: '',
      //imageBaseUrl: 'http://localhost:58967/',
      imageBaseUrl: process.env.VUE_APP_Img_URL,
      // 审核对话框内容
      aduitCheckDialog: false,
      // 审核对话框建议内容
      auditForm: {
        isCheck: false,
        remark: '',
      },
      // 发货订单的数据
      deliveryProductData: [],
      deliverProductDialog: false,
      deliverProductForm: {
        customerNumber: '',
        productList: [],
      },
      //
      tablekey: false,
      deliverDetialDialog: false,
      deliverDetialData: [],

      printDataDialog: false,

      upInvoiceDialog: false,
      upInvoiceForm: {
        invoiceType: '',
        // courierNumber: '',
        file: [],
      },
      pdfShowDialog: false,
      pdfSrc: '',

      // 修改制作订单备注2内容
      mackOrderRemark2Dialog: false,
      mackOrderRemark2Form: {
        id: undefined,
        remark2: '',
      },
    }
  },
  components: {
    barcode: VueBarcode,
  },
  created() {
    this.getMackOrderData()
    this.getStateOptions()
    this.getTypeOptions()
  },
  methods: {
    async getStateOptions() {
      let { data } = await getMackOrderStateOptions()
      this.stateOptions = data
    },
    async getTypeOptions() {
      let { data } = await getMackOrderTypeOptions()
      this.typeOptions = data
    },
    async openPdfDialog(url) {
      let { data } = await axios.get('/api/MackOrder/InvoicePdfStream', {
        params: {
          url,
        },
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
          'X-Token': this.$store.getters.token || '',
        },
        responseType: 'blob',
      })

      // let data = encodeURIComponent(process.env.VUE_APP_API_URL + '?url=' + url)

      const blob = new Blob([data], {
        type: `application/pdf;charset-UTF-8`, // word文档为msword,pdf文档为pdf
      })
      const objectURL = URL.createObjectURL(blob)
      this.pdfSrc = objectURL
      console.log(this.pdfSrc)
      this.pdfShowDialog = true
    },
    queryInvoiceTypeSearch(queryString, cb) {
      let arr = [
        {
          value: '纸质发票',
        },
        {
          value: '电子发票',
        },
      ]

      var restaurants = arr
      var results = restaurants.filter((u) => {
        return u.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      })
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    httpRequestInvoice(option) {
      this.upInvoiceForm.file.push(option)
    },
    // 上传前处理
    beforeUploadInvoice(file) {
      let fileSize = file.size
      const FIVE_M = 20 * 1024 * 1024
      //大于5M，不允许上传
      if (fileSize > FIVE_M) {
        this.$message.error('最大上传200M')
        return false
      }
      //判断文件类型，必须是xlsx格式
      let fileName = file.name
      let reg = /^.+(\.pdf)$/
      if (!reg.test(fileName)) {
        this.$message.error('只能上传.pdf文件!')
        return false
      }
      return true
    },
    // 文件数量过多时提醒
    handleExceedInvoice() {
      this.$message({ type: 'error', message: '最多支持1个文件上传' })
    },
    closeUpInvoiceDialogHandle() {
      this.upInvoiceForm = {
        invoiceType: '',
        // courierNumber: '',
        file: [],
      }
      this.$refs.uploadInvoice.clearFiles()
    },
    async upInvoiceFrom() {
      if (
        !this.upInvoiceForm.invoiceType ||
        this.upInvoiceForm.file.length == 0
      ) {
        return this.$message.error('当前的发票信息填写不完整')
      }
      const formData = new FormData()
      formData.append('orderId', this.orderDetailData.id)
      formData.append('invoiceType', this.upInvoiceForm.invoiceType)
      formData.append('file', this.upInvoiceForm.file[0].file)

      await upInvoiceFile(formData)

      this.$message.success('发票上传成功')
      let { data } = await getMackOrderDetail(this.orderDetailData.id)
      this.orderDetailData = data

      this.getMackOrderData()

      this.upInvoiceDialog = false

      // axios
      //   .post('/api/MackOrder/UpInvoiceLogFile', formData, {
      //     baseURL: process.env.VUE_APP_API_URL,
      //     timeout: 300000,
      //     headers: { 'Content-Type': 'multipart/form-data' },
      //   })
      //   .then(async (res) => {
      //     console.log(res)
      //     if (res && res.data && res.data.code == 200) {
      //       this.$message.success('发票上传成功')
      //     } else {
      //       console.log(res.message)
      //       this.$message.error('发票上传失败')
      //     }

      //     let { data } = await getMackOrderDetail(this.orderDetailData.id)
      //     this.orderDetailData = data

      //     this.getMackOrderData()

      //     this.upInvoiceDialog = false
      //   })
      //   .catch((err) => {
      //     this.$message.error(err)
      //   })
    },

    async getMackOrderData() {
      let filterData = { ...this.queryFilter }

      if (this.timer) {
        filterData.startTime = timeOffset(this.timer[0])
        filterData.endTime = timeOffset(this.timer[1])
      }

      let { data } = await getMackOrder(filterData)
      this.mackOrderData = data.data.map((item) => {
        item['loading'] = false
        item['productList'] = null
        return item
      })
      this.queryFilter.total = data.total
    },
    flashTable() {
      this.queryFilter.pageIndex = 1
      this.getMackOrderData()
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getMackOrderData()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getMackOrderData()
    },
    pushAddMackOrder() {
      this.$router.push({ path: '/Sale/MackOrderAdd' })
    },
    // 操作粘贴板使用
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    // 打开订单详情
    async OrderDetail(id) {
      let { data } = await getMackOrderDetail(id)
      this.orderDetailData = data

      this.orderDetailDataDialog = true
    },
    // 制单文件下载
    async OrderDetailDownLoad() {
      await downLoadMackOrder(this.orderDetailData.id)
    },
    // 删除订单
    async deleteMakckOrder(row) {
      this.$confirm('此操作将删除当前订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteOrder(row.id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getMackOrderData()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    changFile(file, fileList) {
      const isImage =
        file.raw.type === 'image/jpeg' ||
        file.raw.type === 'image/png' ||
        file.raw.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isImage) {
        this.$message.error('上传文件只能是jpg,jpeg,png图片格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 2M!')
      }

      if (isImage && isLt2M) {
        // 校验成功后，开始文件上传
        this.uploadlist(file, fileList)
      } else {
        if (this.fileList.length != 0) {
          this.fileList = this.fileList.filter((item) => {
            return item.uid != file.uid
          })
        } else {
          this.fileList = []
        }
        return false
      }
      //选择文件后，给fileList对象赋值
      //  this.fileList = fileList
      // this.$refs.upload_attach_item.validate();
    },
    // 文件上传
    uploadlist(files, fileList) {
      // 判断是否是初次上传，若不是则将数组进行拼接
      if (this.fileList) {
        this.fileList = this.fileList.concat(files)
      } else {
        this.fileList[0] = files
      }
    },
    // 删除文件
    removeFile(file, fileList) {
      this.fileList = this.fileList.filter((item) => {
        return item.uid != file.uid
      })
    },
    // 文件超出长度
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    // el-upload 点击上传的文件
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.imgeShowDialog = true
    },
    // 清空文件上传
    clearUpload(refName) {
      this.$refs[refName].clearFiles()
      this.fileList = []
    },
    // 提交审核材料对话框提交
    async auditDialogCheck() {
      let formData = new FormData()

      let files = this.fileList.map((item) => {
        return item.raw
      })

      files.forEach((element) => {
        formData.append('file', element)
      })

      await upAuditeFile(this.orderDetailData.id, formData)

      let { data } = await getMackOrderDetail(this.orderDetailData.id)
      this.orderDetailData = data

      this.getMackOrderData()

      this.auditeDialog = false
    },
    // 获取时间线对应的图标颜色
    getTimeTimeLineColor(log) {
      if (log.auditState == 0) {
        return '#909399'
      }

      if (log.auditState == 1) {
        return '#5CB87A'
      }

      if (log.auditState == 2) {
        return '#F56C6C'
      }

      return '#909399'
    },
    openAuditCheckDialog(isCheck) {
      this.auditForm.isCheck = isCheck
      this.aduitCheckDialog = true
    },
    // 提交审核意见
    async aduitCheckCheck() {
      if (this.auditForm.isCheck == false && this.auditForm.remark == '') {
        return this.$message.error('请输入不予通过的理由')
      }

      await auditOrder(
        this.orderDetailData.id,
        this.auditForm.isCheck,
        this.auditForm.remark
      )

      let { data } = await getMackOrderDetail(this.orderDetailData.id)
      this.orderDetailData = data

      this.getMackOrderData()

      this.aduitCheckDialog = false
    },
    // 打开待发货对话框
    async openDelliverDialog() {
      let productData =
        this.orderDetailData.deliveryGoodsList?.reduce(function (pre, cur) {
          return [...pre, ...cur.details]
        }, []) ?? []

      let productList = [...this.orderDetailData.productList].map((item) => {
        item['sendAmount'] = 0
        item['count'] = item.productAmount
        return item
      })

      productData.forEach((item) => {
        let filterItme = productList.filter((pItem) => {
          return (
            pItem.productId == item.productId &&
            pItem.modelName == item.modelName &&
            pItem.productSpecs == item.productSpecs
          )
        })

        if (filterItme && filterItme.length > 0) {
          filterItme[0].sendAmount = parseInt(
            Number(filterItme[0].sendAmount) + Number(item.amount)
          )

          filterItme[0].count = parseInt(
            parseInt(filterItme[0].productAmount) -
              parseInt(filterItme[0].sendAmount)
          )
        }
      })

      this.deliveryProductData = productList

      this.deliverProductDialog = true
    },
    async deliveryDialogCheck() {
      if (this.deliverProductForm.customerNumber == '') {
        return this.$message.error('当前快递信息不能为空')
      }

      let prodd = [...this.deliveryProductData]
      prodd = prodd.filter((item) => {
        return item.count && item.count != 0
      })

      prodd = prodd.map((item) => {
        return {
          productId: item.productId,
          modelName: item.modelName,
          productSpecs: item.productSpecs,
          productName: item.productName,
          amount: item.count,
        }
      })

      await deliveryOrder(
        this.orderDetailData.id,
        this.deliverProductForm.customerNumber,
        prodd
      )

      let { data } = await getMackOrderDetail(this.orderDetailData.id)
      this.orderDetailData = data

      this.getMackOrderData()

      this.deliverProductDialog = false
    },
    opendeliverDetialDialog(data) {
      this.deliverDetialData = data
      this.deliverDetialDialog = true
    },
    editMackOrder(id) {
      this.$router.push({ path: '/MackOrderEdit/' + id })
    },
    async expandChange(row, expandedRows) {
      if (!row.productList) {
        row.loading = true
        try {
          let data = await getMackOrderDetail(row.id)

          this.mackOrderData.forEach((item, index, arr) => {
            if (item.id == row.id) {
              arr[index].productList = data.data.productList
            }
          })

          row.loading = false
        } catch (error) {
          row.loading = false
        }
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []

      if (data) {
        columns.forEach((column, index) => {
          // 第一列索引
          if (index === 0) {
            sums[index] = '总价'
            return
          }

          // 第二列商品名称
          if (index === 3) {
            const values = data.map((item) => Number(item[column.property]))
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ' 件'
            } else {
              sums[index] = 'N/A'
            }
            return
          }

          // 第四列总价
          if (index === 4) {
            const priceArr = data.map((item) => {
              return Number(item['productPrice'] * item['productAmount'])
            })

            if (!priceArr.every((value) => isNaN(value))) {
              sums[index] = priceArr
                .reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
                .toFixed(2)
              sums[index] += ' 元'
            } else {
              sums[index] = 'N/A'
            }
            return
          }

          // 第四列总价
          if (index === 5) {
            const priceArr = data.map((item) => {
              return Number(item['productTaxPrice'] * item['productAmount'])
            })

            if (!priceArr.every((value) => isNaN(value))) {
              sums[index] = priceArr
                .reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
                .toFixed(2)
              sums[index] += ' 元'
              return
            } else {
              sums[index] = 'N/A'
              return
            }
          }

          sums[index] = ''
        })
      }

      this.$nextTick((_) => {
        this.$refs['productRef'].doLayout()
      })

      return sums
    },
    zjPrintPage() {
      let newStr = this.$refs.zhijieprintBox.innerHTML
      window.document.body.innerHTML = newStr // 把需要打印的指定内容赋给body
      window.print() // 调用浏览器的打印功能打印指定区域
      location.reload()
    },
    // 打印出货单
    printpage() {
      let newStr = this.$refs.printBox.innerHTML
      window.document.body.innerHTML = newStr // 把需要打印的指定内容赋给body
      window.print() // 调用浏览器的打印功能打印指定区域
      location.reload()
    },
    pagination(pageNo, pageSize, array) {
      var offset = (pageNo - 1) * pageSize
      return offset + pageSize >= array.length
        ? array.slice(offset, array.length)
        : array.slice(offset, offset + pageSize)
    },
    tagType(val) {
      if (val == 0) {
        return 'info'
      }
      if (val == 1 || val == 2 || val == 9) {
        return 'warning'
      }

      if (val == 3 || val == 10 || val == 5) {
        return 'success'
      }

      return 'danger'
    },
    openRemark2Dialog(row) {
      this.mackOrderRemark2Form.id = row.id
      this.mackOrderRemark2Form.remark2 = row.remark2

      this.mackOrderRemark2Dialog = true
    },
    async submitRemark2() {
      await changeMackOrderRemark2(this.mackOrderRemark2Form)

      this.getMackOrderData()
      this.mackOrderRemark2Dialog = false
    },
  },
  computed: {
    orderDetailDataTotalPrice() {
      return this.orderDetailData.productList
        .reduce((prev, item) => {
          if (!isNaN(item.productTotalPrice)) {
            let value = Number(item.productTotalPrice)
            return prev + value
          } else {
            return prev
          }
        }, 0)
        .toFixed(2)
    },
    canAudit() {
      return (
        // 待支付状态
        this.orderDetailData.orderState != 0 &&
        // 审核失败状态
        this.orderDetailData.orderState != 8
      )
    },
    canDelivery() {
      return (
        // 待发货状态
        this.orderDetailData.orderState != 2 &&
        // 部分发货状态
        this.orderDetailData.orderState != 9
      )
    },
    canInvoiceLog() {
      return (
        this.orderDetailData.orderState == 2 ||
        this.orderDetailData.orderState == 3 ||
        this.orderDetailData.orderState == 4 ||
        this.orderDetailData.orderState == 5 ||
        this.orderDetailData.orderState == 9 ||
        this.orderDetailData.orderState == 10
      )
    },
    deliveryGoodsDetialDataComp() {
      console.log(this.deliverDetialData)
      return this.deliverDetialData?.filter((u) => {
        return u.isSoft == false
      })
    },
    deliveryGoodsDetialCount() {
      return this.deliveryGoodsDetialDataComp.reduce((pre, cur) => {
        pre += cur.amount
        return pre
      }, 0)
    },
  },
}
</script>
<style lang="scss" scoped>
.expand_box {
  margin: 10px 20px;
}
.el-select {
  width: 100%;
}

.print_box {
  width: 700px;
  border: 1px solid transparent;
  margin: 100px auto 0;
  font-size: 16px;
  .print_box_head {
    position: relative;
    height: 50px;
    text-align: center;
    line-height: 50px;
    .barcode_style {
      position: absolute;
      top: 0;
      right: 0;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px;
    }
  }
  .print_box_info {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: 10px;
    div {
      width: 230px;
      flex-shrink: 0;
      margin-bottom: 5px;
    }
  }
  .print_table_box {
    font-size: 12px;
    table {
      border-top: 1px solid black;
      border-left: 1px solid black;
      th,
      td {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
    thead {
      line-height: 28px;
      th {
        font-weight: normal;
      }
    }
    tbody {
      line-height: 16px;
      td {
        padding-left: 5px;
        box-sizing: border-box;
      }
    }
  }
  .print_box_foot {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: 10px;
    div {
      width: 266px;
      flex-shrink: 0;
      margin-bottom: 5px;
    }
  }
}
</style>
