import request from '@/utils/request'

const URL = {
  getOrdersUrl: '/api/order/GetOrder',
  getOrdersStatusOptionsUrl: '/api/order/GetOrderStatusOptions',
  getOrderInfoByOrderNumber: '/api/order/getOrderInfoByOrderNumber',
  getMackOrderUrl: '/api/mackorder/GetOrderList',
  addMackOrder: '/api/mackorder/addOrder',
  getMackOrderDetail: '/api/mackorder/GetMackOrderDetail',
  downLoadMackOrder: 'api/MackOrder/downLoadOrder',
  getOrderDataByNumber: 'api/MackOrder/GetMackOrderDetailByNumber',
  deleteOrder: 'api/MackOrder/deleteOrder',
  upAuditeFile: 'api/MackOrder/UpAuditeFile',
  getAuditeLog: 'api/MackOrder/getAuditeLog',
  getDeliveryDetial: 'api/MackOrder/getDeliveryDetial',
  auditOrder: 'api/MackOrder/auditOrder',
  deliveryOrder: 'api/MackOrder/deliveryOrder',
}

// 获取订单信息
export function getOrder (data) {
  return request({
    url: URL.getOrdersUrl,
    method: 'post',
    data,
  })
}

export function getKeziFile (id) {
  return request({
    url: "api/Order/GetKeziFile",
    method: 'post',
    data: { keziid: id }
  })
}

export function getKeziFile2 (id) {
  return request({
    url: "api/Order/GetKeziFile2",
    method: 'post',
    data: { keziid: id }
  })
}


// 获取订单状态列表
export function getOrderStatusOptions () {
  return request({
    url: URL.getOrdersStatusOptionsUrl,
    method: 'get',
  })
}

// 获取订单状态列表
export function getMackOrderTypeOptions () {
  return request({
    url: "api/MackOrder/GetMackOrderTypeOptions",
    method: 'get',
  })
}

// 获取具体订单详情
export function getOrderByNumber (orderNumber) {
  return request({
    url: URL.getOrderInfoByOrderNumber,
    method: 'get',
    params: { orderNumber },
  })
}

// 获取制单表详情
export function getMackOrder (data) {
  return request({
    url: URL.getMackOrderUrl,
    method: 'post',
    data,
  })
}

// 新增制单
export function addMackOrder (data) {
  return request({
    url: URL.addMackOrder,
    method: 'post',
    data,
  })
}

/**
 * 更具制单表Id,更新制单表
 * @param {int} id
 * @param {Object} data
 * @returns
 */
export function UpdateMackOrder (id, data) {
  return request({
    url: '/api/MackOrder/Update',
    method: 'post',
    params: { id },
    data,
  })
}

// 获取制单信息
export function getMackOrderDetail (id) {
  return request({
    url: URL.getMackOrderDetail,
    method: 'get',
    params: { id },
  })
}

// 下载制单文件
export function downLoadMackOrder (data) {
  return request({
    url: URL.downLoadMackOrder,
    method: 'get',
    params: { id: data },
    responseType: 'blob',
  })
}

export function getOrderDataByNumber (orderNumber) {
  return request({
    url: URL.getOrderDataByNumber,
    method: 'get',
    params: { orderNumber },
  })
}

export function deleteOrder (id) {
  return request({
    url: URL.deleteOrder,
    method: 'get',
    params: { id },
  })
}

// 上传审核文件
export function upAuditeFile (orderid, files) {
  return request({
    url: URL.upAuditeFile,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { orderid },
    //这部分非常重要，否则formdata会被转格式
    transformRequest: [
      function () {
        return files
      },
    ],
    data: files,
  })
}

// 上传发票
export function upInvoiceFile (data) {
  return request({
    url: '/api/MackOrder/UpInvoiceLogFile',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    //这部分非常重要，否则formdata会被转格式
    // transformRequest: [
    //   function () {
    //     return files
    //   },
    // ],
    data,
  })
}

// 获取审核日志
export function getAuditeLog (orderId) {
  return request({
    url: URL.getAuditeLog,
    method: 'get',
    params: { orderId },
  })
}

// 获取发货日志
export function getDeliveryDetial (orderId) {
  return request({
    url: URL.getDeliveryDetial,
    method: 'get',
    params: { orderId },
  })
}

// 审核订单
export function auditOrder (orderId, isCheck, remark) {
  return request({
    url: URL.auditOrder,
    method: 'post',
    params: { orderId },
    data: {
      isCheck,
      remark,
    },
  })
}

// 订单发货
export function deliveryOrder (orderId, courierNumber, data) {
  return request({
    url: URL.deliveryOrder,
    method: 'post',
    data: { orderId, courierNumber, data },
  })
}

// 获取订单状态选项
export function getMackOrderStateOptions () {
  return request({
    url: 'api/MackOrder/getMackOrderStateOptions',
    method: 'get',
  })
}

// 修改制作订单备注2信息
export function changeMackOrderRemark2 (data) {
  return request({
    url: 'api/MackOrder/ChangeRemark2',
    method: 'post',
    data
  })

}

